<template>
  <div class="side-menu-element">
    <router-link
      tag="div"
      class="menu_element_block flex items-center align-middle rounded-full"
      :to="'/' + path"
    >
      <span
        class="avtive_position"
      ></span>
      <svg-icon
        :name="icon"
        class="menu_element_icon w-10 h-10"
        original
      />
      <div
        class="menu_element ml-2"
      >
        {{ menu }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'side-menu-element',
  props: {
    menu: String,
    icon: String,
    path: String
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/mixin";
  .svg-fill {
    fill: transparent;
  }
  .menu_element_block {
    padding: .5rem 2.5rem;
    margin: .1rem 0;
    position: relative;
    color: $white;
    cursor: pointer;
    width: 100%;
    .avtive_position {
      position: absolute;
      left: 2rem;
      top: 6%;
      height: 3rem;
      width: 80%;
      z-index: 0;
      visibility: hidden;
    }
    .menu_element_icon {
      z-index: 1;
    }
    .menu_element {
      width: 100%;
      z-index: 1;
    }
    &:hover {
      .avtive_position {
        visibility: hidden;
      }
    }
    &.router-link-active {
      color: $white;
      .avtive_position {
        visibility: visible;
        background: #0B5382;
        border-radius: 9999rem;
      }
      .menu_element_icon::v-deep {
        path {
          // stroke: $white;
        }
      }
    }
  }
</style>
