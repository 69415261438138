<!--
About Page
Created at 09/04/2021 11:45
Author: Khaliq ALI
!-->
<template>
  <div class="home_page">
    <the-header/>
    <div class="content_body flex">
      <div class="nav_sidebar">
        <the-sidebar/>
      </div>
      <section class="main_content ">
        <router-view class="viewer container m-auto" />
      </section>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/the-header'
import TheSidebar from '@/components/the-sidebar'
export default {
  name: 'index',
  components: { TheSidebar, TheHeader }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .home_page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    height: 100%;

    .nav_sidebar {
      // height: calc(100% - 5rem);
    }

    .content_body {
      width: 100%;
      height: calc(100% - 5rem);
      overflow-y: hidden;

      .main_content {
        height: 100%;
        overflow: scroll;
        width: 100%;
        // padding: 2rem 4rem 4rem 1.5rem;
        background: $pass_content_background;

        .viewer {
          height: 100%;
        }
        .points.container {
          padding: 0;
        }
      }
    }
  };
</style>
