<script>
import ButtonBase from '@/components/helper/add/button-base.vue'
import PopupBase from '@/components/helper/add/popup-base.vue'
import http from '@/plugins/http'
import apiRoute from '@/router/api-routes'

export default {
  name: 'update-admin',
  components: {
    PopupBase,
    ButtonBase
  },
  props: {
    adminId: Number
  },
  data () {
    return {
      loader: false,
      name: '',
      surname: '',
      email: '',
      phone: '',
      error: false
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    saveNewData () {
      this.loader = true
      http.patch(apiRoute.baseURL + apiRoute.updateAdmin, {
        id: this.adminId,
        firstName: this.surname,
        lastName: this.name,
        phone: this.phone,
        email: this.email
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.$store.dispatch('CREATE_USER', response)
        this.$emit('openUpdateSuccess', true)
      }).catch(error => {
        console.log(error)
        this.loader = false
      })
    },
    getUserInfo () {
      http.post(apiRoute.baseURL + apiRoute.findUser, {
        userId: this.adminId
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.name = response.lastName
        this.surname = response.firstName
        this.phone = response.phone
        this.email = response.email
      }).catch(error => {
        console.log(error)
        this.loader = false
      })
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    goBack () {
      this.$emit('clickToGoBack', false)
    }
  }
}
</script>

<template>
  <div class="update-user">
    <popup-base>
      <div class="begin bg-white m-auto w-1/2 rounded-lg p-8">
        <div class="header flex items-center justify-between">
          <div class="header-title font-medium text-2xl text-black">Modifier mes informations</div>
          <div class="delete-icon cursor-pointer" @click="goBack">
            <svg-icon name="ic_close" original class="w-4 h-4"/>
          </div>
        </div><br>
        <div class="body mt-6">
          <div class="form bg-white rounded-lg m-auto">
            <div class="login-form">
              <div class="label mb-2">{{ $t('name') }}</div>
              <div class="input">
                <label>
                  <input
                    v-model="name"
                    type="text"
                    class="ipt border rounded-lg w-full p-4"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('surname') }}</div>
              <div class="input">
                <label>
                  <input
                    v-model="surname"
                    type="text"
                    class="ipt border rounded-lg w-full p-4"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('email') }}</div>
              <div class="input">
                <label>
                  <input
                    v-model="email"
                    type="email"
                    readonly
                    class="ipt border rounded-lg w-full p-4"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('phone') }}</div>
              <div class="input">
                <label>
                  <input
                    v-model="phone"
                    type="tel"
                    maxlength="8"
                    class="ipt border rounded-lg w-full p-4"
                    @keypress="isNumberOnly"
                  >
                </label>
              </div>
            </div><br>
<!--            <div
              v-if="error"
              class="text-red-500 text-center mt-2"
            >
              {{ errorAdmin }}
            </div>-->
          </div><br>
          <div class="delete-button flex items-center justify-end mt-4">
            <div class="deletion">
              <button-base
                label="Enregistrer"
                :is-loading="loader"
                @click.native="saveNewData"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<style scoped lang="scss">

</style>
