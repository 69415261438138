<template>
  <div class="the-sidebar h-screen">
    <div class="the_sidebar border-r">
      <div class="side_header">
      </div>
      <div class="side_menu_container is-recd mt-12">
        <menu-element :menu="$t('dashboardMenu')" icon="ic_dashboard" path="dashboard"></menu-element>
        <menu-element :menu="$t('usersMenu')" icon="ic_users" path="users"></menu-element>
        <menu-element :menu="$t('membershipMenu')" icon="ic_adhesion" path="membership"></menu-element>
        <menu-element :menu="$t('pointsMenu')" icon="ic_point-ssr" path="points" ></menu-element>
        <menu-element :menu="$t('articleMenu')" icon="ic_article" path="article"></menu-element>
        <menu-element :menu="$t('quizMenu')" icon="ic_quizzz" path="quiz"></menu-element>
        <menu-element :menu="$t('forumMenu')" icon="ic_forum_menu" path="forum"></menu-element>
        <menu-element :menu="$t('adminMenu')" icon="ic_admin" path="admin"></menu-element>
      </div>
    </div>
  </div>
</template>

<script>
import menuElement from '../components/helper/side-menu-element'
export default {
  name: 'the-sidebar',
  components: {
    menuElement
  },
  data () {
    return {
      isOpenMenu: false,
      currentUser: {}
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the_sidebar{
    // border-right: solid 1px $raams_header_bottom;
    width: 18rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    background-color: $pass_marron;

    .side_menu_container{
      //padding-top: 4.5rem;
      height: calc(100% - 5rem);
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
    }
  }
  ::-webkit-scrollbar {
    width: 1px;
  }
</style>
