<template>
  <div class="the-header w-full">
    <div class="begin bg-white border-b flex items-center justify-between px-16 w-full">
      <div class="first-part py-4 flex items-center">
        <div class="logo cursor-pointer">
          <svg-icon
            name="ic_logo"
            original
            class="w-1/3"
          />
        </div>
      </div>
      <div class="second-part flex items-center h-full">
        <div class="notifs border-r py-4 mr-4 pr-8" @click="showNotification">
          <div class="is-notifs relative cursor-pointer">
            <div class="cloche">
              <svg-icon
                name="ic_notif"
                original
                class="w-8 h-8"
              />
            </div>
            <div v-if="numberNotif > 0" class="nbre absolute top-0 right-0 bg-red-700 text-white rounded-full p-1 text-sm">
              {{ numberNotif }}
            </div>
          </div>
        </div>
        <div class="profile-bloc py-4 relative">
          <div class="flex items-center cursor-pointer" @click="showUserInfo">
            <div class="picture rounded-full w-12 h-12 mr-4">
              <svg-icon
                name="ic_user"
                original
                class="w-12 h-12 rounded-full"
              />
            </div>
            <div class="name-bloc mr-4">
              <div class="name mb-1 text-black font-semibold text-lg capitalize">
                {{ currentUser.firstName + ' ' + currentUser.lastName }}
              </div>
              <div class="function capitalize">
                {{ currentUser.userType }}
              </div>
            </div>
            <div class="arrows">
              <svg-icon
                name="ic_dropdown"
                original
                class="w-3 h-3"
              />
            </div>
          </div>
          <div
            v-if="userInfo"
            class="infos absolute bg-white rounded-lg p-4 z-50 shadow min-w-72"
          >
            <div class="flex items-center justify-between">
              <div class="email pb-4 border-b">{{ currentUser.email }}</div>
              <div class="update ml-4 cursor-pointer" @click="openUpdate">
                <svg-icon
                  name="ic_update"
                  original
                  class="w-5 h-5"
                />
              </div>
            </div>
            <div
              class="deconnect pt-4 text-red-600 text-lg font-semibold cursor-pointer"
              @click="logOutStore"
            >
              {{ $t('logout') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="notification" class="notifications z-50 rounded-lg absolute">
        <div class="titre p-4 text-center text-white rounded-t font-semibold">
          {{ $t('notifications') }}
        </div>
        <div class="is-notifffsss overflow-y-scroll">
          <div
            v-for="(item, i) in allNotifications"
            :key="i"
            class="notif-content p-4 border-b cursor-pointer"
            @click="selectNotif(i)"
          >
            <div v-if="item.isRead === false" class="conteneur">
              <span v-if="item.content === 'Utilisateur Authentifié'">{{ item.content + ': ' }} <b>{{ item.user.pseudo }}</b></span>
              <span v-else>{{ item.content + ' de: ' }} <b>{{ item.user.pseudo }}</b></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <update-admin v-if="isUpdate" :admin-id="currentUser.id" @clickToGoBack="clickToGoBack" @openUpdateSuccess="openUpdateSuccess" />

    <success-add v-if="isSuccess"  message="Informations mis à jour avec succès" button-label="Retour" @closeSuccess="closeSuccess"/>
  </div>
</template>

<script>
import { collection, getDocs, query, orderBy, limit, where, doc, updateDoc } from 'firebase/firestore'
import { fireStore } from '../main'
import UpdateAdmin from '@/components/helper/update-admin.vue'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'the-header',
  components: { SuccessAdd, UpdateAdmin },
  data () {
    return {
      currentUser: {},
      userInfo: false,
      notification: false,
      allNotifications: [],
      isUpdate: false,
      isSuccess: false
    }
  },
  created () {
    this.currentUser = this.$store.getters.userInfos
    console.log(this.currentUser)
    console.log(this.parseJwt(this.$store.getters.loginToken))
    this.getAllNotifs()
  },
  computed: {
    numberNotif: function () {
      const tab = []
      for (let i = 0; i < this.allNotifications.length; i++) {
        if (this.allNotifications[i].isRead === false) {
          tab.push(this.allNotifications[i])
        }
      }
      return tab.length
    }
  },
  methods: {
    openUpdateSuccess (value) {
      this.isSuccess = value
      this.isUpdate = false
    },
    closeSuccess (value) {
      this.isSuccess = value
      window.location.reload()
    },
    clickToGoBack (value) {
      this.isUpdate = value
    },
    openUpdate () {
      this.isUpdate = true
      this.userInfo = false
    },
    async selectNotif (index) {
      const vm = this
      console.log(vm.allNotifications[index])
      const washingtonRef = doc(fireStore, 'adminNotification', vm.allNotifications[index].id)
      await updateDoc(washingtonRef, {
        isRead: true
      }).then(response => {
        console.log(response + ': okkk')
        vm.allNotifications[index].isRead = true
        if (vm.allNotifications[index].type === 'USER') {
          vm.$router.push({ path: '/users' })
        } else if (vm.allNotifications[index].type === 'SSR') {
          vm.$router.push({ path: '/membership' })
        }
        vm.allNotifications.splice(index, 1)
        vm.notification = false
        vm.numberNotif = vm.numberNotif() - 1
      }).catch(error => {
        console.log(error)
      })
    },
    async getAllNotifs () {
      const vm = this
      const querySnapshot = await getDocs(query(collection(fireStore, 'adminNotification'), where('isRead', '==', false), orderBy('createdAt', 'desc'), limit(100)))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        vm.allNotifications.push(doc.data())
      })
    },
    showNotification () {
      this.notification = !this.notification
      this.userInfo = false
    },
    showUserInfo () {
      this.userInfo = !this.userInfo
      this.notification = false
    },
    logOutStore () {
      this.$store.dispatch('IS_LOGOUT')
        .then(response => {
          console.log(response)
          this.$router.push({ path: '/login' })
        }).catch(error => {
          console.log(error)
        })
    },
    parseJwt (token) { // DECRYPT TOKEN TO GET USER INFORMATION
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .nbre {
    font-size: .5rem;
  }
  .notifications {
    display: block;
    position: absolute;
    height: auto;
    min-height: 400px;
    width: 30%;
    right: 1rem;
    background-color: white;
    top: 65px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .322);
    z-index: 50;

  }
  .is-notifffsss {
    max-height: 400px;
  }
  .titre {
    background: $pass_marron;
  }
  .notif-content {
    background: rgba(178, 139, 103, .15);
  }
  .infos {
    min-width: 17rem;
  }
</style>
